import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Menu = () => {
  const renderTableRows = (items) => {
    return items.map((item, index) => (
      <tr key={index} className={index % 2 === 0 ? "bg-light p-2" : "bg-dark p-2"}>
        <td className="p-2" colSpan={item.colSpan || 1} dangerouslySetInnerHTML={{ __html: item.name }}></td>
        {item.price && <td className="text-right p-2" dangerouslySetInnerHTML={{ __html: item.price }}></td>}
      </tr>
    ))
  }

  const renderBuffetItems = (items) => {
    return items.map((item, index) => (
      <td key={`buffet-item-${index}`} className={index % 2 === 0 ? "text-left" : "text-right"}>
        {item.map((subItem, subIndex) => (
          <React.Fragment key={`${index}-${subIndex}`}>
            {subItem}
            <br />
          </React.Fragment>
        ))}
      </td>
    ))
  }

  const appetizers = [
    { name: "Steamed Mussels <i>served with melted butter</i>", price: "$5.95" },
    { name: "Bacon-Wrapped Scallops <i>barbequed and served with honey mustard and seafood sauce</i>", price: "$11.25" },
    { name: "Charge based on full group size.", price: "", colSpan: 2 },
    { name: "Cheeseballs and crackers, fruit melon <i>and much more</i> also available.", price: "", colSpan: 2 }
  ]

  const mainMenuSelections = [
    { name: "Boneless Pork Steak", price: "$16.45" },
    { name: "Chicken Breast <i>(6 oz, boneless)</i>", price: "$18.75" },
    { name: "Strip Loin Steak 10oz", price: "$27.95 <br /> + mushrooms $3.25 <br /> + onions $1.75" },
    { name: "Salmon", price: "$28.45" },
    { name: "Tofu Veggie Kabob", price: "$16.45** <br /> available without tofu" },
    { name: "Grab n' Go - 'On a Bun' mix", price: "$19.25 <br /> Chicken, Sausage, Burger, Pulled Pork. Choose any of the above grab n' go options, or combine them all. Includes full salad buffet." },
    { name: "Lobster", price: "$16.45 <br /> + Lobster cost (you set the size and quantity)" },
    { name: "Combinations of any of the above six choices available to groups.", price: "", colSpan: 2 },
    { name: "Unlimited Hip of Beef - Includes two desserts", price: "$25.70 <br /> + mushrooms $3.25 <br /> + onions $1.75" },
    { name: "Pork Buffet <i>(whole succulent roast pig) - </i>Includes two desserts", price: "<em>(based on min. 75 person group)</em> <br/> $32.05" },
    { name: "Chicken(60%) / Pork(40%)", price: "$18.00** most popular item**" },
    { name: "Sausages, Chicken breast, Hamburger 6oz fresh, <br /> pulled pork on a bun, Pork steak, BEYOND MEAT Burger or <br /> Pork Back Ribs (1/3 rack)", price: "$6.50" },
    { name: "Pork side Ribs", price: "$15.00" },
    { name: "Ball Park hot dogs", price: "$3.00" },
    { name: "10oz Strip Loin Steak or Salmon", price: "$15.00" },
    { name: "&nbsp;", price: "***add $3.50 as a stand alone menu*** without buffet" },
    { name: "Children ages 7 - 12 Includes hotdog and adult option.", price: "$9.95" },
    { name: "Infant under 6 <i>(includes hotdog)</i> <br /> - Infants and children not included in group sizing", price: "$1.00" }
  ]

  const buffetItems = [
    [
      "Baked potato",
      "Potato Salad",
      "Coleslaw",
      "Lettuce",
      "Lemons",
      "Corn Relish",
      "Broccoli",
      "Mushrooms",
      "Green Peppers",
      "Horse Radish (with beef)",
      "Whole Wheat Rolls",
      "French Dressing",
      "Thousand Islands",
      "Tomatoes"
    ],
    [
      "Celery",
      "Carrots",
      "Onions",
      "Croutons",
      "Cucumber",
      "Sour Cream",
      "Bacon Bits",
      "Cauliflower",
      "Hot Peppers",
      "White Rolls",
      "Butter",
      "Ranch Dressing",
      "Italian Dressing",
      "Ceasar Dressing"
    ]
  ]

  return (
    <Layout>
      <Seo title="Sizzler BBQ Catering - Our Menu" />
      <div className="container">
        <div className="flex flex-col items-center pt-10">
          <StaticImage
            className="rounded-lg shadow-lg"
            src="../images/bbq3.jpg"
            alt="Beef"
            style={{ opacity: `95%` }}
          />
        </div>

        <div className="text-center pt-10">
          <a href="tel:1-800-805-2227">
            <FontAwesomeIcon icon={faPhone} />
            {"  "}
            Call for free Consultation
          </a>
        </div>

        <div className="flex flex-col items-center pt-10">
          <p className="text-left">
            <strong>Appetizers</strong>
          </p>
          
          <table className="text-center">
            <tbody>
              {renderTableRows(appetizers)}
            </tbody>
          </table>

          <p className="py-2">
            <strong>Main Menu Selections</strong>
          </p>
          <table className="text-center">
            <tbody>
              {renderTableRows(mainMenuSelections)}
            </tbody>
          </table>

          <div style={{ clear: "both" }}></div>
          <StaticImage 
            src="../images/buffet1.jpg"
            alt="Buffet"
            className="img-fluid"
          />
          <br />
          <b>Salad Buffet items included with the menu features: </b>{" "}
  
          <table cellSpacing="10" cellPadding="0" border="0">
            <tbody>
              <tr>
                {renderBuffetItems(buffetItems)}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Menu